import {Form, Input} from 'antd-mobile';
import React from 'react';
import Select from '../../../../Components/Form/Select';
import {
    AromaTypeEnum,
    AromaTypeOptions,
    ChannelsOptions,
    PriceOptions,
    YearOptions,
} from '../../../../Config/Options';
import FormUpload from '../../../../Components/Form/FormUpload';
import {FormInstance} from 'rc-field-form/es';
import {SubmitForm} from '../../service';
import classNames from 'classnames';
import Style from './index.module.scss';
import _ from 'lodash-es';

const rules: {rules: any[]} = {
    rules: [{required: true, message: '请录入此项'}],
};

interface Props {
    form: FormInstance<SubmitForm<string[]>>;
    index: number;
    show: boolean;
}

const InputProps = {
    placeholder: '中文+英文 例：苹果 Apple',
};

const Index = ({form, index, show}: Props) => {
    const winetype = Form.useWatch(['list', index, 'winetype'], form);
    return (
        <div className={classNames({[Style.hide]: !show})}>
            <Form.Item
                label="酒品名称"
                description="Name"
                name={[index, 'winename']}
                {...rules}>
                <Input {...InputProps} type="text" />
            </Form.Item>
            <Form.Item
                label="商品条码"
                description="Batch Number"
                name={[index, 'sptm']}
                {...rules}>
                <Input placeholder="请输入商品条码" type="text" />
            </Form.Item>
            <Select
                {...rules}
                options={YearOptions}
                description="Vintage"
                name={[index, 'productiondate']}
                label="生产年分"
            />
            <Form.Item
                label="产地"
                description="Regions"
                name={[index, 'jpcd']}
                {...rules}>
                <Input placeholder="请输入产地" type="text" />
            </Form.Item>
            <Select
                {...rules}
                options={ChannelsOptions}
                label="销售渠道"
                description="Sales Channels"
                name={[index, 'xsqd']}
            />
            <Select
                {...rules}
                options={AromaTypeOptions}
                label="酒品香型"
                description="Category"
                name={[index, 'winetype']}
            />
            {_.first(winetype) === AromaTypeEnum.Other && (
                <Form.Item
                    label="具体香型"
                    preserve={false}
                    description="Specific fragrance type"
                    name={[index, 'winetypedetail']}
                    {...rules}>
                    <Input
                        {...InputProps}
                        placeholder="请输入中文+英文，例：中国 China"
                        type="text"
                    />
                </Form.Item>
            )}

            <Select
                {...rules}
                options={PriceOptions}
                label="零售价"
                description="Recommended Retail Price"
                name={[index, 'lsj']}
            />
            <Form.Item
                label="酒精度"
                description="Alcohol by Volume"
                name={[index, 'winedegree']}
                extra="%"
                {...rules}>
                <Input
                    {...InputProps}
                    placeholder="精确到小数点后1位"
                    type="number"
                />
            </Form.Item>
            <Form.Item
                label="每瓶毫升数"
                description="Ml per bottle"
                name={[index, 'hss']}
                extra="ml"
                {...rules}>
                <Input placeholder="请输入每瓶毫升数" type="number" />
            </Form.Item>
            <Form.Item
                label="参赛瓶数"
                description="Entries"
                name={[index, 'jpps']}
                extra="瓶"
                {...rules}>
                <Input placeholder="请输入参赛瓶数" type="number" />
            </Form.Item>
            <Form.Item
                description="Image"
                label="酒品图片"
                name={[index, 'jpimg']}
                {...rules}>
                <FormUpload />
            </Form.Item>
        </div>
    );
};

export default Index;
