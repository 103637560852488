import _ from 'lodash-es';
import {MainRequest} from '../../Utils/Request';
import {InvoiceType} from '../../Config/Options';
import {getTimeStr} from '../../Utils/help';

export interface SubmitForm<T> {
    title: string;
    list: DrinkFormItem<T>[];
}

export interface DrinkFormItem<T> {
    winename?: string; // 酒名
    winetype?: T; //类型
    winedegree?: string; //酒精度数
    productiondate?: T; //生产年份
    winetypedetail?: string; //其他时填写
    jpcd?: string; //产地
    sptm?: string; //商品条码
    xsqd?: T; //销售渠道
    lsj?: T; //零售价
    hss?: string; //毫升数
    jpps?: string; //瓶数
    jpimg?: T; //酒品图片
}

export function saveSubmit(data: SubmitForm<string[]>) {
    return MainRequest.post<SuccessData<string>>(`/enlist`, {
        ...data,
        list: data.list.map(item => {
            return {
                ...item,
                jpimg: item?.jpimg?.join(','),
                productiondate: _.first(item?.productiondate),
                winetype: _.first(item?.winetype),
                xsqd: _.first(item?.xsqd),
                lsj: _.first(item?.lsj),
            };
        }),
    }).then(res => res.data.data);
}

export interface GetSubmitDetail extends SubmitForm<string> {
    amount: string; //支付金额
    zfsj: string; //支付金额
    id?: string;
}

export function getSubmitDetail(id?: string) {
    return MainRequest.get<SuccessData<GetSubmitDetail>>(
        `/enlist/selectDetailById/${id}`,
    )
        .then(res => res.data.data)
        .then(res => {
            return {
                ...res,
                list: res.list.map(item => {
                    return {
                        ...item,
                        jpimg: item?.jpimg?.split(','),
                        productiondate: [
                            getTimeStr(item.productiondate!, 'YYYY-MM-DD'),
                        ],
                        winetype: [item.winetype!],
                        xsqd: [item.xsqd!],
                        lsj: [item.lsj!],
                    };
                }),
            };
        });
}

export interface Company {
    name: string; //企业名称
    taxid: string; //纳税号
    contactsname: string; //联系人姓名
    contactsphone: string; //联系人电话
    contactsemail: string; //联系人邮箱
    yyzj: string | string[]; //营业执照
}

export interface GetCompany extends Company {
    id?: string;
    openid: string;
}

export function getCompany() {
    return MainRequest.get<SuccessData<GetCompany>>(`/company/select`).then(
        res => {
            return res.data.data;
        },
    );
}

export interface Invoice {
    id: string;
    fplx: InvoiceType[]; //类型
    gsmc: string; //名称
    sh: string; //税号
    yx: string; //邮箱
}

export function getInvoice(id?: string) {
    return MainRequest.get<SuccessData<Invoice>>(
        `/enlist/selectDetailById/${id}`,
    )
        .then(res => res.data.data)
        .then(res => {
            console.log(res?.fplx!);
            return {
                ...res,
                fplx: [res?.fplx!],
            };
        });
}

export function putInvoice(data: Invoice) {
    return MainRequest.put(`/enlist/update`, {
        ...data,
        fplx: _.first(data?.fplx),
    });
}
