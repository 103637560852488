/**
 * 数据请求钩子
 * @author menghao
 * ------------------------------------------------
 * 简单请求
 * const [list] = useRequest(() => Service.Course.getXXX(111));
 *
 * ------------------------------------------------
 * 简单请求 + 请求状态 + 错误状态 + 请求函数
 * const [list, { loading, error }, getList] = useRequest(() => Service.Course.getXXX(111));
 *
 * ------------------------------------------------
 * 不立即执行 + 不显示错误
 * const [list, , getList] = useRequest(() => Service.Course.getXXX(111), { autoStart: false, silence: true });
 *
 * ------------------------------------------------
 * 数据分页
 * const [page, setPage] = useState(1);
 * const [list] = useRequest(() => Service.Course.getXXX(page), {}, [page]);
 *
 *
 * changelog
 * 2021/7/12  为了解决异步请求在组件卸载后报错的问题, 改为使用useAsyncFn
 */

/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react';
import {useAsyncFn, useLatest, useMount, useUpdateEffect} from 'react-use';
import {errorMessage} from '../help';
import {Toast} from 'antd-mobile';

interface StatusType {
    loading: boolean;
    error: any;
}

interface RequestConfig {
    autoStart?: boolean; // 默认自动发送请求
    silence?: boolean; // 静默模式, 出错也不弹出提示
}

const ConfigDefault: Partial<RequestConfig> = {
    autoStart: true,
    silence: false,
};

export default function useRequest<S>(
    callback: () => Promise<S>,
    config: RequestConfig = {},
    deps: any[] = [],
) {
    const [state, doFetch] = useAsyncFn(callback, deps);
    const doFetchLatest = useLatest(doFetch);

    Object.assign(config, {...ConfigDefault, ...config});

    type DataType = S | undefined;

    function refresh() {
        return doFetchLatest.current();
    }

    useMount(() => {
        if (config.autoStart) doFetch();
    });

    useUpdateEffect(() => {
        doFetch();
    }, deps);

    useEffect(() => {
        if (!state.error) return;
        const msg = errorMessage(state.error);
        if (!config.silence && msg !== 'Request aborted') Toast.show(msg);
    }, [state.error]);

    return [state.value, state, refresh] as [
        DataType,
        StatusType,
        () => Promise<any>,
    ];
}
