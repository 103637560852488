import {PickerColumnItem} from 'antd-mobile/es/components/picker-view';
import {PickerDate} from 'antd-mobile/es/components/date-picker/util';
import dayjs from 'dayjs';

// 从错误对象中获取错误消息
export function errorMessage(e: any, defaultMessage: string = '发生未知错误') {
    if (!e) return defaultMessage;
    const errCodes = [400, 401];
    if (e.isAxiosError && e.message === 'Network Error') return '网络异常';
    if (e && errCodes.includes(e.status) && e.data && e.data.message)
        return e.data.message;
    if (e instanceof Error && e.message) return e.message;
    if (e && !errCodes.includes(e.status)) return e?.data?.msg;
    return defaultMessage;
}

export function getQueryString(name: string) {
    //获取url中参数
    let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    let r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return '';
}
export function RenderPickerValue(value: (PickerColumnItem | null)[]) {
    return value.every(item => item === null)
        ? '请选择'
        : value.map(item => item?.label ?? '未选择').join(' - ');
}

export function RenderPickerDateValue(
    value: PickerDate | null,
    format?: string,
) {
    return value ? getTimeStr(value!, 'YYYY-MM-DD') : '请选择';
}

export function getTimeStr(
    time: string | PickerDate,
    format: string = 'YYYY-MM-DD HH:mm:ss',
) {
    return dayjs(time).format(format);
}
