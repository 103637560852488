import React, {useEffect, useState} from 'react';
import Style from './index.module.scss';
import {Button, Form, Input, Toast} from 'antd-mobile';
import wx from 'weixin-js-sdk';
import useRequestFn from '../../Utils/Hooks/useRequestFn';
import {errorMessage} from '../../Utils/help';
import {useParams} from 'react-router-dom';
import Spin from '../../Components/Spin';
import {getInvoice, Invoice, putInvoice} from '../SubmitRegistration/service';
import Select from '../../Components/Form/Select';
import {InvoiceType, InvoiceTypeOptions} from '../../Config/Options';
import {emailRegExp} from '../../Utils/pattern';
import _ from 'lodash-es';

const rules: {rules: any[]} = {
    rules: [{required: true, message: '请录入此项'}],
};

export default function Index() {
    const {id} = useParams();
    const [submit] = useRequestFn(putInvoice);
    const [form] = Form.useForm<Invoice>();

    const [isSubmit, setIsSubmit] = useState(false);

    const [getData, {loading}] = useRequestFn(getInvoice);
    useEffect(() => {
        if (id) {
            getData(id).then(res => {
                form.setFieldsValue(res);
                if (res?.yx) {
                    setIsSubmit(true);
                }
            });
        }
    }, [id]);

    async function onFinish(form: Invoice) {
        try {
            await submit({
                ...form,
                id: id!,
            });
            Toast.show('提交成功');
            wx.miniProgram.redirectTo({
                url: '/pages/submit/history/index',
            });
        } catch (e) {
            Toast.show(errorMessage(e));
        }
    }

    const fplx = Form.useWatch('fplx', form);

    return (
        <Spin loading={loading}>
            <div className={Style.root}>
                <Form
                    className={Style.form}
                    layout="horizontal"
                    style={{
                        '--prefix-width': '8em',
                    }}
                    disabled={isSubmit}
                    onFinish={onFinish}
                    form={form}
                    footer={
                        <div className={Style.pd}>
                            <Button
                                color="primary"
                                block
                                className={Style.btn}
                                disabled={isSubmit}
                                onClick={() => form.submit()}>
                                提交
                            </Button>
                        </div>
                    }>
                    <Select
                        options={InvoiceTypeOptions}
                        label="发票类型"
                        name="fplx"
                        {...rules}
                    />
                    <Form.Item
                        label={
                            _.first(fplx) === InvoiceType.Individual
                                ? '开票名称'
                                : '企业名称'
                        }
                        name="gsmc"
                        {...rules}>
                        <Input placeholder="请输入" type="text" />
                    </Form.Item>
                    {_.first(fplx) === InvoiceType.Company && (
                        <Form.Item label="纳税人识别号" name="sh" {...rules}>
                            <Input placeholder="请输入" type="text" />
                        </Form.Item>
                    )}
                    <Form.Item
                        label="邮箱"
                        name="yx"
                        rules={[
                            {
                                type: 'string',
                                required: true,
                                pattern: emailRegExp,
                                message: '邮箱格式不正确',
                            },
                        ]}>
                        <Input placeholder="请输入" type="email" />
                    </Form.Item>
                </Form>
            </div>
        </Spin>
    );
}
