import React, {RefObject} from 'react';
import {Form, Picker, PickerRef} from 'antd-mobile';
import {RenderPickerValue} from '../../../Utils/help';
import {FormItemProps} from 'antd-mobile/es/components/form/form-item';

interface Props extends FormItemProps {
    options: LabelValue[];
}
const Index = ({options, ...props}: Props) => {
    return (
        <Form.Item
            {...props}
            trigger="onConfirm"
            onClick={(e, datePickerRef: RefObject<PickerRef>) => {
                datePickerRef.current?.open();
            }}>
            <Picker columns={[options]}>
                {value => RenderPickerValue(value)}
            </Picker>
        </Form.Item>
    );
};

export default Index;
