import axios from 'axios';
import {Toast} from 'antd-mobile';
import wx from 'weixin-js-sdk';
import {errorMessage} from './help';

export const MainRequest = axios.create({baseURL: '/api'});

MainRequest.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');

        config.headers['Authorization'] = token;
        config.headers['InterType'] = 'wx';

        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

MainRequest.interceptors.response.use(
    async response => {
        if (response.data.code === 901) {
            wx.miniProgram.switchTab({
                url: '/pages/index/index',
            });
            throw response;
        }
        if (response.data.code !== 200) {
            Toast.show(errorMessage(response));
            throw response;
        }
        return response;
    },
    async error => {
        Toast.show(errorMessage(error));
        return Promise.reject(error);
    },
);
