import React, {useEffect, useMemo, useState} from 'react';
import {Button, Form, Input, ResultPage, Toast} from 'antd-mobile';
import {getCompany, getSubmitDetail, saveSubmit, SubmitForm} from './service';
import {AddCircleOutline, DownOutline} from 'antd-mobile-icons';
import _ from 'lodash-es';
import useRequest from '../../Utils/Hooks/useRequest';
import {ValidateErrorEntity} from 'rc-field-form/es/interface';
import wx from 'weixin-js-sdk';
import useRequestFn from '../../Utils/Hooks/useRequestFn';
import {errorMessage, getTimeStr} from '../../Utils/help';
import {useParams} from 'react-router-dom';
import Tips from './Components/Tips';
import Title from '../../Components/Title';
import classNames from 'classnames';
import FormList from './Components/FormList';
import Spin from '../../Components/Spin';
import Style from './index.module.scss';

export default function Index() {
    const params = useParams();
    const [showIndex, setShowIndex] = useState([0]);
    const [submit] = useRequestFn(saveSubmit);
    const [form] = Form.useForm<SubmitForm<string[]>>();
    const [companyData] = useRequest(getCompany);

    const [getData, {loading}] = useRequestFn(getSubmitDetail);
    const [detail, setDetail] = useState<any>();

    const [id, setId] = useState(params.id);

    useEffect(() => {
        if (id) {
            getData(id).then(res => {
                form.setFieldsValue(res);
                setDetail(res);
            });
        }
    }, [id]);

    async function onFinish(form: SubmitForm<string[]>) {
        try {
            const id = await submit(form);
            setId(id);
            Toast.show('提交成功');
        } catch (e) {
            Toast.show(errorMessage(e));
        }
    }

    function doPay() {
        wx.miniProgram.navigateTo({
            url: `/pages/pay/index?id=${id}&openid=${companyData?.openid}`,
        });
    }

    async function onFinishFailed({errorFields}: ValidateErrorEntity) {
        const current = errorFields[0];
        if (current.name.length > 1) {
            Toast.show(
                `请完整输入第${(current.name[1] as number) + 1}个产品的信息`,
            );
        } else {
            Toast.show(current.errors[0]);
        }
    }

    function toggleShow(index: number) {
        if (id) {
            return;
        }
        setShowIndex(_index => {
            const arr = _index;
            if (showIndex.includes(index)) {
                const currentIndex = _.findIndex(arr);
                arr.splice(currentIndex, 1);
            } else {
                arr.push(index);
            }
            return _.uniq(arr);
        });
    }

    const list: any[] = Form.useWatch(['list'], form);

    const details = [
        {
            label: '商户订单号',
            value: detail?.id,
            bold: true,
        },
        {
            label: '支付金额',
            value: `¥ ${detail?.amount}`,
            bold: true,
        },
        {
            label: '支付时间',
            value: getTimeStr(detail?.zfsj!),
        },
    ];

    const isPay = useMemo(() => {
        return !!(detail?.amount && detail?.zfsj);
    }, [detail?.amount, detail?.zfsj]);

    const node = (
        <div className={Style.root}>
            <Form
                className={Style.form}
                disabled={!!id}
                layout="horizontal"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
                initialValues={{
                    list: [{}],
                }}
                footer={
                    <>
                        {!id && (
                            <div className={Style.pd}>
                                <Button
                                    color="primary"
                                    block
                                    className={Style.btn}
                                    onClick={() => form.submit()}>
                                    保存
                                </Button>
                            </div>
                        )}
                    </>
                }>
                {!id && (
                    <>
                        <div className={Style.titlePd}>
                            <Tips />
                            <Title>企业信息</Title>
                        </div>
                        <Form.Item label="企业名称" extra={companyData?.name} />
                        <Form.Item
                            label="联系人姓名"
                            extra={companyData?.contactsname}
                        />
                        <Form.Item
                            label="联系人电话"
                            extra={companyData?.contactsphone}
                        />
                        <Form.Item
                            label="联系人邮箱"
                            extra={companyData?.contactsemail}
                        />
                        <Form.Item
                            label="邮寄地址"
                            extra={companyData?.taxid}
                        />
                    </>
                )}
                {<></>}
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: '请输入参赛标题"',
                        },
                    ]}
                    name="title"
                    label="参赛标题">
                    <Input placeholder="例：xx酒厂 n款酒样参赛报名" />
                </Form.Item>
                <div className={Style.titlePd}>
                    <Title>产品信息</Title>
                </div>
                <Form.Array
                    name="list"
                    onAdd={operation => operation.add({name: '张三'})}
                    renderAdd={() => (
                        <>
                            {!id && (
                                <Button color="primary" fill="outline" block>
                                    <AddCircleOutline /> 添加
                                </Button>
                            )}
                        </>
                    )}
                    renderHeader={({index}, {remove}) => (
                        <div className={Style.header}>
                            <span className={Style.label}>产品{index + 1}</span>
                            {!id && (
                                <div
                                    className={Style.arrow}
                                    onClick={() => toggleShow(index)}>
                                    <DownOutline
                                        className={classNames(
                                            {
                                                [Style.down]:
                                                    showIndex.includes(index),
                                            },
                                            Style.arrow,
                                        )}
                                    />
                                </div>
                            )}
                            {!id && list?.length > 1 && (
                                <div
                                    className={Style.deleteBox}
                                    onClick={() => remove(index)}>
                                    删除
                                </div>
                            )}
                        </div>
                    )}>
                    {fields =>
                        fields.map(({index, key}) => (
                            <div key={key}>
                                <FormList
                                    key={key}
                                    form={form}
                                    index={index}
                                    show={!!id || showIndex.includes(index)}
                                />
                            </div>
                        ))
                    }
                </Form.Array>
            </Form>
        </div>
    );

    if (!id) {
        return node;
    }

    return (
        <Spin loading={loading}>
            <ResultPage
                primaryButtonText={!isPay && '去支付'}
                onPrimaryButtonClick={doPay}
                status="success"
                title={
                    <div style={{fontSize: 15}}>
                        {isPay ? '支付成功' : '提交成功'}
                    </div>
                }
                description={
                    isPay && (
                        <>
                            <span
                                style={{
                                    fontSize: 32,
                                    color: '#ffffff',
                                    marginRight: 4,
                                }}>
                                ¥
                            </span>
                            <span style={{fontSize: 48, color: '#ffffff'}}>
                                {detail?.amount}
                            </span>
                        </>
                    )
                }
                details={isPay ? details : []}>
                {node}
            </ResultPage>
        </Spin>
    );
}
