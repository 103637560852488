export enum ChannelEnum {
    Online = '线上',
    OffOnline = '线下',
    All = '线上线下都有',
}
const ChannelsOptions = [
    {value: ChannelEnum.Online, label: '线上'},
    {value: ChannelEnum.OffOnline, label: '线下'},
    {value: ChannelEnum.All, label: '线上线下都有'},
];

export enum AromaTypeEnum {
    SauceFlavorType = '酱香型',
    AromaticType = '浓香型',
    Fragrance = '清香型',
    Other = '其他香型',
}
const AromaTypeOptions = [
    {value: AromaTypeEnum.SauceFlavorType, label: '酱香型'},
    {value: AromaTypeEnum.AromaticType, label: '浓香型'},
    {value: AromaTypeEnum.Fragrance, label: '清香型'},
    {value: AromaTypeEnum.Other, label: '其他香型'},
];

export enum InvoiceType {
    Individual = '个人',
    Company = '企业',
}
export const InvoiceTypeOptions = [
    {value: InvoiceType.Individual, label: '个人'},
    {value: InvoiceType.Company, label: '企业'},
];

export enum PriceEnum {
    A = '0-150元',
    B = '151-299元',
    C = '300-599元',
    D = '600-1000元',
    E = '1000元以上',
}
const PriceOptions = [
    {value: PriceEnum.A, label: '0-150元'},
    {value: PriceEnum.B, label: '151-299元'},
    {value: PriceEnum.C, label: '300-599元'},
    {value: PriceEnum.D, label: '600-1000元'},
    {value: PriceEnum.E, label: '1000元以上'},
];

function getYear() {
    const currentYear = new Date().getFullYear();
    const yearArray: LabelValue[] = [];
    for (let i = currentYear; i >= currentYear - 100; i--) {
        yearArray.push({value: `${i}-01-01`, label: i.toString()});
    }
    return yearArray;
}

const YearOptions = getYear();

export {ChannelsOptions, AromaTypeOptions, PriceOptions, YearOptions};
