import React from 'react';
import Style from './index.module.scss';
import {Grid, Image, ImageViewer} from 'antd-mobile';
import {CloseCircleOutline} from 'antd-mobile-icons';
import lodash from 'lodash-es';
import COS from 'cos-js-sdk-v5';
import Config from '../../../Config';
import useRequestFn from '../../../Utils/Hooks/useRequestFn';
import FileSelect from '../../FileSelect';
interface Props {
    value?: string[];
    onChange?: (value: string[]) => void;
    multiple?: boolean;
    maxCount?: number;
}
const Index = ({onChange, multiple, value = [], maxCount = 1}: Props) => {
    const cos = new COS({
        SecretId: Config.Cos.SecretId,
        SecretKey: Config.Cos.SecretKey,
    });

    function onRemove(index: number) {
        const v = lodash.cloneDeep(value);
        v?.splice(index, 1);
        onChange?.(v || []);
    }

    const [doUpload, {loading}] = useRequestFn(handleFileInUploading);

    function handleFileInUploading(file: File) {
        return new Promise<string>((resolve, reject) => {
            cos.uploadFile(
                {
                    Bucket: Config.Cos.Bucket /* 填写自己的 bucket，必须字段 */,
                    Region: Config.Cos.Region /* 存储桶所在地域，必须字段 */,
                    Key: file.name /* 存储在桶里的对象键（例如:1.jpg，a/b/test.txt，图片.jpg）支持中文，必须字段 */,
                    Body: file, // 上传文件对象
                    SliceSize:
                        1024 *
                        1024 *
                        5 /* 触发分块上传的阈值，超过5MB使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */,
                },
                function (err, data) {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(`https://${data.Location}`);
                    }
                },
            );
        });
    }

    async function onFileChange(files: File[]) {
        const res = await doUpload(files[0]);
        onChange?.([...value, res]);
    }

    function onSelectFile() {
        // @ts-ignore
        wx.chooseImage({
            count: 1, // 默认9
            sizeType: ['original'], // 可以指定是原图还是压缩图，默认用原图
            sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
            success: function (res: {tempFiles: {path: any}[]}) {
                const filePath = res.tempFiles[0].path;
                const fileName = filePath.substr(filePath.lastIndexOf('/') + 1);
                console.log(filePath, fileName);
            },
        });
    }

    return (
        <div className={Style.root}>
            <Grid columns={3} gap={15}>
                {value?.map((item, index) => {
                    return (
                        <Grid.Item key={item}>
                            <div className={Style.img}>
                                <CloseCircleOutline
                                    className={Style.close}
                                    onClick={() => onRemove(index)}
                                />
                                <Image
                                    onClick={() =>
                                        ImageViewer.show({image: item})
                                    }
                                    src={item}
                                    width={60}
                                    height={60}
                                    fit="cover"
                                />
                            </div>
                        </Grid.Item>
                    );
                })}
                {value?.length < maxCount && (
                    <Grid.Item>
                        {!loading && (
                            <FileSelect
                                loading={false}
                                buttonClass={Style.fileBtn}
                                multiple={multiple}
                                onFileSelect={onFileChange}
                            />
                        )}
                    </Grid.Item>
                )}
            </Grid>
        </div>
    );
};
export default Index;
