import React, {useMemo, useRef} from 'react';
import Style from './index.module.scss';
import {v4} from 'uuid';
import Spin from '../Spin';
import {AddOutline} from 'antd-mobile-icons';

interface FileUploaderProps {
    buttonClass?: string;
    buttonText?: string;
    loading?: boolean;
    onFileSelect: (files: File[]) => void;
    multiple?: boolean;
}

export default function FileSelect({
    buttonClass,
    loading,
    buttonText = '点击上传',
    onFileSelect,
    multiple = false,
}: FileUploaderProps) {
    const fileRef = useRef<any>(null);
    function onChange() {
        onFileSelect(fileRef.current.files);
    }
    function onClick() {
        fileRef.current.value = '';
    }
    const id = useMemo(() => {
        return v4();
    }, []);
    return (
        <div className={buttonClass} onClick={onClick}>
            <Spin loading={loading!}>
                <div className={Style.btn}>
                    {!loading && (
                        <label htmlFor={id}>
                            <AddOutline />
                        </label>
                    )}
                </div>
            </Spin>
            <input
                ref={fileRef}
                type="file"
                id={id}
                style={{display: 'none'}}
                multiple={multiple}
                accept="image/*"
                onChange={onChange}
            />
        </div>
    );
}
