import React, {useEffect} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import './Style/reset.scss';
import {getQueryString} from './Utils/help';
import SubmitRegistration from './Page/SubmitRegistration';
import InvoiceForm from './Page/InvoiceForm';

function App() {
    useEffect(() => {
        localStorage.setItem('token', getQueryString('token'));
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/submitRegistration/:id?"
                    element={<SubmitRegistration />}
                />
                <Route path="/invoice/:id" element={<InvoiceForm />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
