import Styles from './index.module.scss';
import Title from '../../../../Components/Title';

const Index = () => {
    return (
        <div className={Styles.root}>
            <Title>参赛须知</Title>
            <div className={Styles.p2} style={{marginTop: 0}}>
                征集内容
            </div>
            <div className={Styles.p}>
                烈酒(各种香型白酒以及威士忌、白兰地、伏特加、朗姆酒等)、黄酒、果露酒
            </div>
            <div className={Styles.p2}>报名时间</div>
            <div className={Styles.p}>即日起至2024年8月20日</div>
            <div className={Styles.p2}>报名费用</div>
            <div className={Styles.p}>2000元/款</div>
            <div className={Styles.p2}>报名流程</div>
            <div className={Styles.p}>
                1、微信扫描小程序码，填写企业与酒品信息，并完成支付
            </div>
            <div className={Styles.p}>2、寄送样品酒6瓶</div>
            <div className={Styles.p}>
                寄送地址:山东省烟台市芝罘区机场路1号(神马)国际烈酒(中国)大奖赛样品库
                联系人:孟然 电话:16600019522
            </div>
            <div className={Styles.p}>联系人:孟然</div>
            <div className={Styles.p}>电话:16600019522</div>
        </div>
    );
};

export default Index;
